import { gql } from '@apollo/client'

/* eslint-disable camelcase */
export enum BackgroundFactorIndicatorLabel {
  school_tempen_students = 'Skoltempen elever',
  absence_total_percentage = 'Frånvaro',
  students_per_teacher = 'Elever per lärare',
  teacher_sick_leave = 'Sjukfrånvaro',
  staff_turnover = 'Personalomsättning',
  foreign_background = 'Elever med utländsk bakgrund',
  teachers_with_college_degrees = 'Lärare med pedagogisk examen',
  parents_with_higher_education = 'Föräldrar med akademisk utbildning',
  teacher_questionnaire = 'HME',
  household_financial_status_low="Hushållens ekonomiska status låg",
  household_type_single='Hushållstyp ensamstående',
  residency_time_under_2_year='Kvarboende mindre än 2 år'
}

export enum BackgroundFactorIndicatorCamelCaseKeyLabel {
  absencePercentage = 'Frånvaro',
  schoolTempenStudents = 'Skoltempen elever',
  studentsPerTeacher = 'Elever per lärare',
  foreignBackground = 'Elever med utländsk bakgrund',
  teachersWithCollegeDegrees = 'Lärare med pedagogisk examen',
  parentsHigherEducation = 'Föräldrar med akademisk utbildning',
  staffTurnover = 'Personalomsättning',
  teacherQuestionnaire = 'HME',
  teacherSickLeave = 'Sjukfrånvaro',
  householdFinancialStatusLow="Hushållens ekonomiska status låg",
  householdTypeSingle='Hushållstyp ensamstående',
  residencyTimeUnder2Year='Kvarboende mindre än 2 år'
}

export const BackgroundService = {
  GET_BACKGROUND_FACTORS_MEAN_IMPACT_VALUES: gql`
    query FeaturesMeanImpactGbpEligibility($municipalityId: Int!) {
      featuresMeanImpactGbpEligibility(municipalityId: $municipalityId) {
        name
        eligibilityImpactValue
        gBPImpactValue
      }
    }
  `,
}
